import PopoverButton from '@watershed/ui-core/components/PopoverButton';
import MoreIcon from '@watershed/icons/components/More';
import { DataGridCellExpandAction } from '@watershed/ui-core/components/DataGrid/DataGridCellAction';

import OverflowList from './OverflowList';
import { Chip } from '@mui/material';

export type OverflowChipListItem<T> = T & {
  label: string;
};

type PopoverProps<T> = {
  type: 'popover';
  renderPopover: RenderOverFlowMenuPopover<T>;
  onClickPopoverButton?: (e: React.MouseEvent) => void;
};

type DialogProps = {
  type: 'dialog';
  onClick: () => void;
};

type ItemDisplay<T> = PopoverProps<T> | DialogProps;

type RenderOverFlowMenuPopover<T> = (props: {
  items: Array<OverflowChipListItem<T>>;
  overflowItems: Array<OverflowChipListItem<T>>;
  nonOverflowItems: Array<OverflowChipListItem<T>>;
}) => JSX.Element;

interface OverflowListMenuProps<T> {
  items: Array<OverflowChipListItem<T>>;
  overflowItems: Array<OverflowChipListItem<T>>;
  itemDisplay: ItemDisplay<T>;
}

function OverflowListPopoverButton<T>({
  items,
  overflowItems,
  itemDisplay,
}: Omit<OverflowListMenuProps<T>, 'itemDisplay'> & {
  itemDisplay: PopoverProps<T>;
}) {
  const { onClickPopoverButton, renderPopover } = itemDisplay;
  return (
    <PopoverButton
      flat
      endIcon={null}
      buttonLabel={
        overflowItems.length > 0 ? (
          `+${overflowItems.length}`
        ) : (
          <MoreIcon size={16} sx={{ marginTop: '6px' }} />
        )
      }
      onClick={onClickPopoverButton}
      sx={{
        color: (theme) => theme.typography.body2.color,
        fontSize: (theme) => theme.typography.body3.fontSize,
        fontVariantNumeric: 'tabular-nums',
        paddingInline: 0.75,
        paddingBlock: 0,
        height: (theme) => theme.spacing(3),
        minWidth: 'auto',
        flexShrink: 0,
        border: 0,
        boxShadow: 'none',
        bgcolor: 'transparent',
        // Because ObjectList disables direct PopoverButton child styles
        fontWeight: (theme) => `${theme.typography.fontWeightBold} !important`,
        '&:hover': {
          backgroundColor: (theme) => `${theme.palette.grey20} !important`,
        },
        '&:focus, &:focus-visible': {
          border: 0,
          boxShadow: 'none',
        },
      }}
      alignRight
    >
      {renderPopover({
        items,
        overflowItems,
        nonOverflowItems: items.filter((item) => !overflowItems.includes(item)),
      })}
    </PopoverButton>
  );
}

function OverflowListDialogButton<T>({
  overflowItems,
  itemDisplay,
}: Omit<OverflowListMenuProps<T>, 'itemDisplay'> & {
  itemDisplay: DialogProps;
}) {
  const { onClick } = itemDisplay;
  return (
    <>
      <span>
        {overflowItems.length > 0 ? `+${overflowItems.length}` : null}
      </span>
      <DataGridCellExpandAction onClick={onClick} />
    </>
  );
}

function OverflowListMenu<T>({
  items,
  overflowItems,
  itemDisplay,
}: OverflowListMenuProps<T>) {
  if (itemDisplay.type === 'popover') {
    return (
      <OverflowListPopoverButton
        items={items}
        overflowItems={overflowItems}
        itemDisplay={itemDisplay}
      />
    );
  } else {
    return (
      <OverflowListDialogButton
        items={items}
        overflowItems={overflowItems}
        itemDisplay={itemDisplay}
      />
    );
  }
}

interface OverflowListProps<T> {
  items: Array<OverflowChipListItem<T>>;
  itemDisplay: ItemDisplay<T>;
  alignItems?: React.ComponentProps<typeof OverflowList>['alignItems'];
  maxChipWidth?: string;
  alwaysRenderOverflow?: boolean;
}

export default function OverflowChipList<T>({
  items,
  itemDisplay,
  alignItems,
  maxChipWidth,
  alwaysRenderOverflow = true,
}: OverflowListProps<T>) {
  return (
    <OverflowList
      alwaysRenderOverflow={alwaysRenderOverflow}
      minVisibleItems={1}
      items={items}
      gap={0.5}
      alignItems={alignItems}
      itemRenderer={(item, numShown, index) => (
        <Chip
          key={index}
          label={item.label}
          sx={{
            // We want to be able to collapse+truncate the chip if there's only
            // one visible.
            maxWidth: maxChipWidth ?? undefined,
            minWidth: numShown === 1 ? 0 : undefined,
          }}
        />
      )}
      overflowRenderer={({ items, overflownItems }) => (
        <OverflowListMenu
          items={items}
          overflowItems={overflownItems}
          itemDisplay={itemDisplay}
        />
      )}
    />
  );
}
