import { DataGridPremiumProps } from '../../components/DataGrid/DataGrid';

const disableInteractivity: Partial<DataGridPremiumProps> = {
  disableColumnResize: true,
  disableColumnSelector: true,
  disableColumnReorder: true,
  disableColumnMenu: true,
};

export default disableInteractivity;
