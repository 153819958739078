import { useReferenceOrgsQuery } from '@watershed/shared-frontend/generated/urql';
import useLocalStorageState from '@watershed/shared-frontend/hooks/useLocalStorageState';
import { getGqlResultData } from '@watershed/shared-frontend/utils/errorUtils';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import IconButton from '@watershed/ui-core/components/IconButton';
import StarEmptyIcon from '@watershed/icons/components/StarEmpty';
import StarFillIcon from '@watershed/icons/components/StarFill';
import groupBy from 'lodash/groupBy';
import React from 'react';
import { z } from 'zod';
import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import { getCurrentDevEnv } from '@watershed/shared-frontend/utils/devEnv';
import BlankSlate from '@watershed/ui-core/components/BlankSlate';
import { GQWatershedPlanLegacy } from '@watershed/shared-universal/generated/graphql';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { Typography } from '@mui/material';
import TextLink from '@watershed/ui-core/components/TextLink';
import gql from 'graphql-tag';
import {
  ReferenceOrg,
  ReferenceOrgGroup,
  REFERENCE_ORGS,
  ORG_LABELS,
  OMITTABLE_ORG_LABELS,
} from '@watershed/shared-universal/demoPlatformSchemas/canonicalDemoOrgs';

gql`
  query ReferenceOrgs($ids: [ID!]!) {
    organizations(ids: $ids) {
      edges {
        node {
          __typename
          ...OrganizationListPageOrganization
        }
      }
    }
  }
`;

/**
 * Utilities for managing notable, "reference" orgs, whether that is a shared
 * set of global references or series of personal favorites.
 */

export function useFavoriteOrgs() {
  const [favoriteOrgIds, setFavoriteOrgIds] = useLocalStorageState(
    'admin-favorite-orgs',
    [],
    (value) => {
      return z.array(z.string()).parse(value);
    }
  );
  return {
    favoriteOrgIds,
    addFavoriteOrgs: (orgIds: Array<string>) => {
      setFavoriteOrgIds((curValue) => {
        const valueSet = new Set(curValue);
        orgIds.forEach((orgId) => valueSet.add(orgId));
        return sortBy(Array.from(valueSet));
      });
    },
    toggleFavorite: (orgId: string) => {
      setFavoriteOrgIds((curValue) => {
        const valueSet = new Set(curValue);
        if (valueSet.has(orgId)) {
          valueSet.delete(orgId);
        } else {
          valueSet.add(orgId);
        }
        return sortBy(Array.from(valueSet));
      });
    },
    isFavorite: (orgId: string) => {
      return favoriteOrgIds.includes(orgId);
    },
  };
}

export function FavoriteOrgButton(props: {
  orgId: string;
  size?: 'small' | 'large';
}) {
  const size = props.size ?? 'small';
  const { isFavorite, toggleFavorite } = useFavoriteOrgs();
  const sharedIconProps = {
    size: size === 'small' ? 16 : 24,
  };
  return (
    <IconButton
      size="small"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleFavorite(props.orgId);
      }}
      sx={{ padding: 0.5 }}
      tooltip="Favorite this org for easy access on the admin homepage"
    >
      {isFavorite(props.orgId) ? (
        <StarFillIcon
          {...sharedIconProps}
          color={(theme) => theme.palette.warning.main}
        />
      ) : (
        <StarEmptyIcon
          {...sharedIconProps}
          color={(theme) => theme.palette.secondary.main}
        />
      )}
    </IconButton>
  );
}

/**
 * Hardcoded for now - would be nice to persist in a DB!
 */

/**
 * Return a set of reference orgs, merged with local favorites.
 */
export function useReferenceOrgs(): Array<ReferenceOrgGroup> {
  const { favoriteOrgIds } = useFavoriteOrgs();
  const ids = [...REFERENCE_ORGS.map((org) => org.orgId), ...favoriteOrgIds];
  const [result] = useReferenceOrgsQuery({
    variables: { ids },
  });
  const data = getGqlResultData(result);
  const orgs = flattenConnection(data?.organizations);
  const orgsById = groupBy(orgs, (org) => org.id);

  // Can remove if we fetch from the backend!
  const mapInExtraLabels = (org: ReferenceOrg) => {
    const orgFromData = orgsById[org.orgId]?.at(0);
    const extraLabels = [];
    if (orgFromData?.demoOrg) {
      extraLabels.push(ORG_LABELS.demo);
    }
    if (orgFromData?.testOrg) {
      extraLabels.push(ORG_LABELS.test);
    }
    if (orgFromData && !orgFromData.demoOrg && !orgFromData.testOrg) {
      extraLabels.push(ORG_LABELS.customer);
    }
    if (orgFromData?.watershedPlanLegacy === GQWatershedPlanLegacy.NoPlan) {
      extraLabels.push(ORG_LABELS.supplier);
    }
    return {
      ...org,
      labels: [...org.labels, ...extraLabels],
    };
  };

  // Since the reference orgs are hardcoded in our frontend, only show them
  // when we're not in our local dev.
  // If we fetch this from a backend endpoint, we could remove this check.
  const referenceOrgs =
    getCurrentDevEnv() === 'local-dev' ? [] : REFERENCE_ORGS;
  const labels =
    getCurrentDevEnv() === 'local-dev'
      ? []
      : // We try to respect the ORG_LABELS ordering, rather than aggregating the categories
        // off of the reference orgs themselves.
        Object.values(omit(ORG_LABELS, ...OMITTABLE_ORG_LABELS));

  const referenceOrgsById = groupBy(referenceOrgs, (org) => org.orgId);

  const referenceOrgWarning: ReferenceOrgGroup['warning'] = {
    title: (
      <>
        Please avoid using{' '}
        <TextLink href="https://docs.google.com/spreadsheets/d/1FmICIK_fV-Dk1rdf-97Yi6lZfczDxXUFVmiO8KOExc8/edit?pli=1&gid=0#gid=0">
          prospect-facing demo accounts
        </TextLink>{' '}
        to test new features or playing around!
      </>
    ),
    description: (
      <>
        <Typography gutterBottom>
          If you do, then please clean things up after! We want to avoid
          confusion/surprises and keep the product clean during live demos (ex,
          surprise feature flags, saved views with odd names, empty reduction
          plans, random report entries, etc)
        </Typography>
        <Typography gutterBottom>
          Please enable FFs locally (bottom right corner in dashboard) or even
          better - spin-up your own testing environments where you can go crazy!
          😉
        </Typography>
        <Typography>Thank you 🙏 (@csol)</Typography>
      </>
    ),
  };

  const groups: Array<ReferenceOrgGroup> = [
    {
      name: 'Favorites',
      orgs: sortBy(
        [
          ...favoriteOrgIds.map((orgId) => {
            // If we already found it as a reference org, use it.
            const refOrg = referenceOrgsById[orgId]?.at(0);
            if (refOrg) {
              return refOrg;
            }
            // Otherwise, try to augment data using org data
            const org = orgsById[orgId]?.at(0);
            return {
              orgId,
              orgName: org?.name ?? orgId,
              // Check against existing reference orgs
              description: org?.name ?? orgId,
              labels: [],
            };
          }),
        ].map(mapInExtraLabels),
        (org) => org.orgName
      ),
      emptyState: (
        <BlankSlate
          icon={StarEmptyIcon}
          size="small"
          title="No favorites"
          subtitle="Star an org to see it appear here!"
        />
      ),
    },
    labels.length
      ? {
          name: 'All',
          orgs: referenceOrgs.map(mapInExtraLabels),
          warning: referenceOrgWarning,
        }
      : undefined,
    ...labels.map(({ label }) => {
      return {
        name: label,
        orgs: referenceOrgs
          .filter((org) =>
            org.labels.some((orgLabel) => orgLabel.label === label)
          )
          .map(mapInExtraLabels),
        warning: referenceOrgWarning,
      };
    }),
  ].filter(isNotNullish);

  return groups;
}
