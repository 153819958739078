import { SerializableError } from './serializableError';

export enum BackgroundJobErrorReason {
  UNKNOWN = 'UNKNOWN',
  CANCELED = 'CANCELED',
  RESULT_ERROR = 'RESULT_ERROR',
  RESULT_EMPTY = 'RESULT_EMPTY',
  RESULT_UNSUCCESSFUL = 'RESULT_UNSUCCESSFUL',
  RESULT_UNPARSEABLE = 'RESULT_UNPARSEABLE',
}

export class BackgroundJobError extends SerializableError {
  name = 'BackgroundJobError';
  reason: BackgroundJobErrorReason;
  constructor(
    input: unknown,
    reason: BackgroundJobErrorReason = BackgroundJobErrorReason.UNKNOWN,
    details?: Record<string, any>
  ) {
    super(input);
    this.reason = reason;
    if (details) {
      this.details = { ...this.details, ...details };
    }

    if (
      reason === BackgroundJobErrorReason.RESULT_ERROR ||
      reason === BackgroundJobErrorReason.RESULT_UNSUCCESSFUL
    ) {
      this.code = 'BAD_USER_INPUT';
    }

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BackgroundJobError);
    }
    Object.setPrototypeOf(this, BackgroundJobError.prototype);
  }
}

type ParseBackgroundJobResultOutput =
  | {
      status: 'success';
      result: Record<string, any>;
    }
  | {
      status: 'error';
      error: BackgroundJobError;
    };

type JobEntry = {
  error?: any;
  result?: string | object | null;
};

/**
 * Parses a background_job_entry record, looking for errors in all the nooks and
 * crannies.
 */
export function parseBackgroundJobResult(
  job: JobEntry
): ParseBackgroundJobResultOutput {
  if (job?.error) {
    if (job.error.message === 'Canceled') {
      return {
        status: 'error',
        error: new BackgroundJobError(
          job.error,
          BackgroundJobErrorReason.CANCELED
        ),
      };
    }

    return {
      status: 'error',
      error: new BackgroundJobError(
        job.error,
        BackgroundJobErrorReason.UNKNOWN
      ),
    };
  }

  if (!job?.result) {
    return {
      status: 'error',
      error: new BackgroundJobError(
        'Background job record has no error and no result',
        BackgroundJobErrorReason.RESULT_EMPTY,
        { job }
      ),
    };
  }

  let parsedJobResult;
  try {
    if (typeof job.result === 'string') {
      parsedJobResult = JSON.parse(job.result);
    } else {
      parsedJobResult = job.result;
    }
  } catch (error) {
    return {
      status: 'error',
      error: new BackgroundJobError(
        'Failed to parse background job result as JSON',
        BackgroundJobErrorReason.RESULT_UNPARSEABLE,
        { job }
      ),
    };
  }

  if (parsedJobResult.error) {
    return {
      status: 'error',
      error: new BackgroundJobError(
        parsedJobResult.error,
        BackgroundJobErrorReason.RESULT_ERROR,
        { jobResult: parsedJobResult }
      ),
    };
  }

  if (parsedJobResult.success === false) {
    return {
      status: 'error',
      error: new BackgroundJobError(
        'Background job finished but was not successful',
        BackgroundJobErrorReason.RESULT_UNSUCCESSFUL,
        { jobResult: parsedJobResult }
      ),
    };
  }

  return { status: 'success', result: parsedJobResult };
}
