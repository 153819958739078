/* eslint-disable no-restricted-imports */
import {
  SnackbarMessage,
  OptionsObject,
  useSnackbar as useSnackbarNotistack,
} from 'notistack';
import type { ProviderContext as SnackbarType } from 'notistack';
import frontendHoneycomb from '../utils/frontendHoneycomb';
import { CustomSpanAttributes } from '@watershed/shared-universal/constants/otelCustomAttributes';
import { useMemo } from 'react';

export type { SnackbarType };

export default function useSnackbar(): SnackbarType {
  const snackbarNotistack = useSnackbarNotistack();
  return useMemo(
    () => ({
      enqueueSnackbar(message: SnackbarMessage, options?: OptionsObject) {
        if (options?.variant === 'error') {
          void sendErrorEventToHoneycomb();
        }
        return snackbarNotistack.enqueueSnackbar(message, options);
      },
      closeSnackbar: snackbarNotistack.closeSnackbar,
    }),
    [snackbarNotistack]
  );
}

async function sendErrorEventToHoneycomb() {
  await frontendHoneycomb.sendEvent({
    name: CustomSpanAttributes.BROWSER_USER_VISIBLE_ERROR_SNACKBAR,
  });
}
