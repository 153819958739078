import { forwardRef } from 'react';
import IconButton, {
  type IconButtonProps,
} from '@watershed/ui-core/components/IconButton';
import { IconElement } from '@watershed/icons/Icon';
import { mixinSx } from '@watershed/style/styleUtils';
import MaximizeIcon from '@watershed/icons/components/Maximize';

export type DataGridCellActionProps = Omit<
  IconButtonProps,
  // Tooltips currently add a wrapping div, which breaks the absolute positioning.
  // Use <Tooltip> composition instead.
  'children' | 'aria-label' | 'tooltip' | 'tooltipPlacement'
> & {
  visibility?: 'hover' | 'always';
  IconElement: IconElement;
  'aria-label': LocalizedString;
};

// Add forwardRef
export const DataGridCellAction = forwardRef<
  HTMLButtonElement,
  DataGridCellActionProps
>(function DataGridCellAction(
  { visibility = 'hover', IconElement, sx, ...props }: DataGridCellActionProps,
  forwardedRef
) {
  return (
    <IconButton
      size="small"
      ref={forwardedRef}
      {...props}
      sx={mixinSx(
        {
          position: 'absolute',
          right: '8px',
          verticalAlign: 'top',
          lineHeight: 0,
          padding: 0.5,
          transition: (theme) =>
            theme.transitions.create(['opacity'], {
              duration: theme.transitions.duration.shorter,
            }),
          '&:hover': {
            background: 'transparent',
            color: (theme) => theme.palette.primary.main,
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            inset: '1px',
            backgroundColor: (theme) => theme.palette.white,
            zIndex: 0,
            borderRadius: 1,
            '.MuiDataGrid-row:hover &, .MuiDataGrid-row.Mui-hovered &': {
              backgroundColor: (theme) => theme.palette.grey10,
            },
            '.MuiDataGrid-row.Mui-selected &': {
              backgroundColor: (theme) => theme.palette.cobalt10,
            },
          },
          '& svg': {
            position: 'relative',
            zIndex: 1,
          },
        },
        visibility === 'hover' && {
          opacity: 0,
          '.MuiDataGrid-cell:hover &, .MuiDataGrid-cell:focus &, .MuiDataGrid-cell:focus-within':
            {
              opacity: 1,
              color: (theme) => theme.palette.primary.main,
            },
        },
        sx
      )}
    >
      <IconElement size={14} aria-hidden />
    </IconButton>
  );
});

export function DataGridCellExpandAction(
  props: Omit<
    React.ComponentProps<typeof DataGridCellAction>,
    'aria-label' | 'IconElement'
  >
) {
  return (
    <DataGridCellAction
      {...props}
      aria-label="Expand"
      IconElement={MaximizeIcon}
    />
  );
}
