import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function RegionToggle({
  region,
  setRegion,
}: {
  region: string | null;
  setRegion: (region: string | null) => void;
}) {
  return (
    <Stack direction="row" gap={1} maxHeight={32}>
      <ToggleButtonGroup
        value={region}
        exclusive
        onChange={(_, value) => setRegion(value)}
      >
        <ToggleButton value="us" aria-label="us">
          US
        </ToggleButton>
        <ToggleButton value="eu" aria-label="eu">
          EU
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}
