import {
  GridSlotsComponentsProps,
  gridRowSelectionStateSelector,
  useGridApiContext,
  useGridSelector,
} from '../DataGrid/DataGrid';
import { Trans } from '@lingui/react/macro';
import { Stack, Divider, Box, Tooltip } from '@mui/material';
import Button, { ButtonProps } from '../Button';
import { mixinSx } from '@watershed/style/styleUtils';
import DropdownMenu, { DropdownMenuProps } from '../DropdownMenu';
import assertNever from '@watershed/shared-util/assertNever';
import { BulkActionIsland } from '../BulkActionIsland';

export function ObjectListSelectionFooter({
  objectListSelectionFooterActions,
  sx,
}: NonNullable<GridSlotsComponentsProps['footer']>) {
  const apiRef = useGridApiContext();
  const selectionModel = useGridSelector(apiRef, gridRowSelectionStateSelector);
  const rowIds = apiRef.current.getAllRowIds();
  const selectableRowCount = rowIds.filter(
    (id) =>
      apiRef.current.isRowSelectable(id) &&
      apiRef.current.getRowNode(id)?.type !== 'group'
  ).length;
  const hasSelectableRows = !!selectableRowCount;
  const selectedRowCount = Array.from(
    apiRef.current.getSelectedRows().keys()
  ).filter((id) => apiRef.current.getRowNode(id)?.type !== 'group').length;
  const allRowsSelected = selectedRowCount === selectableRowCount;

  const selectAllRows = () => {
    apiRef.current.selectRows(rowIds, true);
  };
  const deselectAllRows = () => {
    apiRef.current.selectRows(rowIds, false);
  };

  return (
    <Box>
      {hasSelectableRows && selectionModel.length > 0 && (
        <BulkActionIsland
          numSelected={selectedRowCount}
          onSelectAll={() => {
            if (!allRowsSelected) {
              selectAllRows();
            } else {
              deselectAllRows();
            }
          }}
          selectAllText={
            allRowsSelected ? (
              <Trans context="Button copy">
                Deselect all ({selectableRowCount})
              </Trans>
            ) : (
              <Trans context="Button copy">
                Select all ({selectableRowCount})
              </Trans>
            )
          }
          actions={
            objectListSelectionFooterActions && (
              <Stack
                direction="row"
                sx={{
                  gap: '4px',
                  justifyContent: 'flex-end',
                }}
              >
                {objectListSelectionFooterActions.map((actionProps, index) => {
                  switch (actionProps.type) {
                    case 'button':
                      return (
                        <FooterButton
                          key={index}
                          disabledTooltip={actionProps.disabledTooltip}
                          {...actionProps.props}
                        />
                      );
                    case 'divider':
                      return (
                        <Divider key={index} flexItem orientation="vertical" />
                      );
                    case 'menu':
                      return <FooterMenu key={index} {...actionProps.props} />;
                    default:
                      assertNever(actionProps);
                  }
                })}
              </Stack>
            )
          }
        />
      )}
    </Box>
  );
}

interface FooterButtonProps extends ButtonProps {
  disabledTooltip?: string;
}

function FooterButton({ disabledTooltip, ...props }: FooterButtonProps) {
  return (
    <Tooltip
      title={props.disabled && disabledTooltip ? disabledTooltip : undefined}
    >
      <Box>
        <Button
          {...props}
          sx={mixinSx(
            {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              color: (theme) => theme.palette.white,
              fontSize: (theme) => theme.typography.caption.fontSize,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.grey80,
                boxShadow: 'none',
                color: (theme) => theme.palette.white,
              },
              '&:focus': {
                backgroundColor: 'transparent',
                border: '0',
                boxShadow: 'none',
                color: (theme) => theme.palette.white,
              },
              '&:focus-visible': {
                backgroundColor: 'transparent',
                boxShadow: `0 0 0 1px rgba(145, 156, 179, 0.6), 0 0 0 2px #041330, 0 0 0 3px rgba(145, 156, 179, 0.6)`,
                color: (theme) => theme.palette.white,
              },
              '&&&.Mui-disabled': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                // eslint-disable-next-line @watershed/no-custom-colors
                color: 'rgb(175, 183, 200)',
              },
            },
            props.sx
          )}
        />
      </Box>
    </Tooltip>
  );
}

function FooterMenu(props: DropdownMenuProps) {
  return (
    <DropdownMenu
      {...props}
      dense
      menuSx={{
        '& .MuiPaper-root': {
          border: (theme) => `1px solid ${theme.palette.grey100}`,
        },
        '& .MuiMenuItem-root': {
          color: (theme) => theme.palette.white,
          background: (theme) => theme.palette.grey80,
          border: (theme) => theme.palette.grey70,
          '&:hover': {
            background: (theme) => theme.palette.grey100,
          },
          '&.Mui-focusVisible': {
            background: (theme) => theme.palette.grey80,
          },
        },
      }}
      triggerSx={{
        background: 'transparent',
        color: 'inherit',
        border: 'none',
        boxShadow: 'none',
        margin: 0,
        fontSize: (theme) => theme.typography.caption.fontSize,
        '&:hover, &:focus': {
          color: 'inherit',
          border: 'none',
          boxShadow: 'none',
          backgroundColor: (theme) => theme.palette.grey80,
        },
        '&:focus-visible': {
          outline: (theme) => `2px solid ${theme.palette.grey70}`,
          outlineOffset: '-2px',
        },
      }}
    />
  );
}
