import dynamic from 'next/dynamic';
import remarkGfm from 'remark-gfm';
import clsx from 'clsx';
import React from 'react';
import { Typography, TypographyProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TextLink from './TextLink';

const ReactMarkdown = dynamic(() => import('react-markdown'));

const useStyles = makeStyles((theme: Theme) => ({
  customReactMarkdown: {
    '& details': {
      marginTop: theme.spacing(2),
      '& summary': {
        color: theme.palette.text.primary,
      },
    },
    '& iframe': {
      width: '100%',
      maxWidth: '100%',
      border: 0,
      margin: theme.spacing(1, 0, 2),
    },
    '& p + p': {
      marginTop: 14,
    },
    '& code': {
      whiteSpace: 'pre-wrap',
    },
    '& td': {
      padding: 4,
    },
  },
}));

export default function CustomReactMarkdown({
  source,
  variant = 'body2' as TypographyProps['variant'],
  className,
  onClickLink,
  linkOpensNewTab,
  ...reactMarkdownProps
}: {
  source: LocalizedString | null;
  variant?: TypographyProps['variant'];
  className?: TypographyProps['className'];
  onClickLink?: (evt: React.MouseEvent<HTMLAnchorElement>) => void;
  linkOpensNewTab?: boolean;
} & Omit<React.ComponentProps<typeof ReactMarkdown>, 'children'>) {
  const classes = useStyles();
  if (!source) {
    return <></>;
  }
  return (
    <ReactMarkdown
      className={clsx(classes.customReactMarkdown, className)}
      remarkPlugins={[remarkGfm]}
      components={{
        h1: ({ node, ...props }) => (
          <Typography variant="h1" paragraph>
            {props.children}
          </Typography>
        ),
        h2: ({ node, ...props }) => (
          <Typography variant="h2" paragraph>
            {props.children}
          </Typography>
        ),
        h3: ({ node, ...props }) => (
          <Typography variant="h4" paragraph>
            {props.children}
          </Typography>
        ),
        h4: ({ node, ...props }) => (
          <Typography variant="h5" paragraph>
            {props.children}
          </Typography>
        ),
        h5: ({ node, ...props }) => (
          <Typography variant="h5" paragraph>
            {props.children}
          </Typography>
        ),
        h6: ({ node, ...props }) => (
          <Typography variant="h6" paragraph>
            {props.children}
          </Typography>
        ),
        a: ({ node, ...props }) => (
          <TextLink
            href={props.href}
            onClick={onClickLink}
            newTab={linkOpensNewTab}
          >
            {props.children}
          </TextLink>
        ),
        p: ({ node, ...props }) => {
          return (
            <Typography
              sx={{ wordWrap: 'break-word' }}
              variant={variant}
              // Necessary to make explicit, because `body3` variant defaults to `span`
              component="p"
            >
              {props.children}
            </Typography>
          );
        },
        li: ({ node, ...props }) => {
          return (
            <Typography component="li" variant={variant}>
              {props.children}
            </Typography>
          );
        },
        td: ({ node, ...props }) => {
          return (
            <td>
              <Typography
                variant={variant}
                // Necessary to make explicit, because `body3` variant defaults to `span`
                component="p"
              >
                {props.children}
              </Typography>
            </td>
          );
        },
      }}
      {...reactMarkdownProps}
    >
      {source}
    </ReactMarkdown>
  );
}
