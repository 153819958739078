// eslint-disable-next-line no-restricted-imports --  this is our wrapper, should be disallowed everywhere else
import useLocalStorageStateLibrary, {
  LocalStorageState,
  LocalStorageOptions,
} from 'use-local-storage-state';
import { parseStoredValue } from './useStorageState';

// Similar to useState, but tries to get its initial
// value from localStorage if it's available.
export default function useLocalStorageState<T>(
  localStorageKey: string,
  defaultValue: T,
  parseJs?: (o: unknown) => T,
  options?: Omit<LocalStorageOptions<T>, 'defaultValue'>
): LocalStorageState<T>;
export default function useLocalStorageState<T>(
  localStorageKey: string,
  defaultValue?: T,
  parseJs?: (o: unknown) => T,
  options?: Omit<LocalStorageOptions<T>, 'defaultValue'>
): LocalStorageState<T | undefined>;
export default function useLocalStorageState<T>(
  localStorageKey: string,
  defaultValue: T | undefined,
  parseJs?: (o: unknown) => T,
  initialOptions?: Omit<LocalStorageOptions<T>, 'defaultValue'>
) {
  const options = { ...initialOptions, defaultValue };
  return useLocalStorageStateLibrary(localStorageKey, {
    serializer: {
      stringify: options.serializer?.stringify ?? JSON.stringify,
      parse: (value: string) =>
        parseStoredValue(
          value,
          localStorageKey,
          defaultValue,
          parseJs,
          options.serializer?.parse
        ),
    },
    ...options,
  });
}
