import {
  useState,
  useRef,
  useLayoutEffect,
  ReactNode,
  ComponentProps,
} from 'react';
import useEffectEventShim from '../hooks/useEffectEvent';

import { useResizeDetector } from 'react-resize-detector';
import { Stack } from '@mui/material';
import useScheduleLayoutEffect from '../hooks/useScheduleLayoutEffect';

export interface OverflowListProps<T> extends ComponentProps<typeof Stack> {
  items: Array<T>;
  itemRenderer: (item: T, numShown: number, index: number) => ReactNode;
  overflowRenderer: (args: {
    items: Array<T>;
    overflownItems: Array<T>;
  }) => ReactNode;
  minVisibleItems?: number;
  alwaysRenderOverflow?: boolean;
  alignItems?: 'start' | 'end';
}

export default function OverflowList<T>({
  items,
  minVisibleItems = 0,
  alwaysRenderOverflow = false,
  overflowRenderer,
  itemRenderer,
  alignItems = 'start',
  ...stackProps
}: OverflowListProps<T>) {
  const [numVisible, setNumVisible] = useState(items.length);

  const hasHiddenItems = items.length > numVisible;

  const schedule = useScheduleLayoutEffect();
  const spacerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setNumVisible(items.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(items)]);

  const maybeOverflow =
    hasHiddenItems || alwaysRenderOverflow
      ? overflowRenderer({
          items,
          overflownItems: items.slice(numVisible),
        })
      : null;

  const containerSize = useResizeDetector();

  const bisect = useEffectEventShim(
    (
      low: number,
      high: number,
      path: Array<{ index: number; result: 'over' | 'under' }>
    ) => {
      let mid = Math.floor(low + (high - low) / 2);

      if (high - low === 1) {
        if (path.find((p) => p.index === high)) {
          mid = low;
        } else {
          mid = high;
        }
      }

      if (path.find((p) => p.index === mid)) {
        const pathEntry = path.findLast((p) => p.result === 'under');
        if (pathEntry) {
          setNumVisible(
            pathEntry.index < minVisibleItems
              ? minVisibleItems
              : pathEntry.index
          );
        }
        return;
      }

      setNumVisible(mid < minVisibleItems ? minVisibleItems : mid);

      schedule('recur', () => {
        const spacerWidth =
          spacerRef.current?.getBoundingClientRect().width ?? 1;

        if (spacerWidth > 1) {
          path.push({ index: mid, result: 'under' });
          bisect(mid, high, path);
        } else if (spacerWidth < 1) {
          path.push({ index: mid, result: 'over' });
          bisect(low, mid, path);
        }
      });
    }
  );

  useLayoutEffect(() => {
    bisect(0, items.length, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerSize.width, JSON.stringify(items)]);

  const spacer = (
    <div
      style={{ flexShrink: 1, width: 1, flexGrow: 1 }}
      ref={spacerRef}
      data-spacer
    />
  );

  return (
    <Stack
      flexDirection="row"
      flexWrap="nowrap"
      width="100%"
      minWidth={0}
      ref={containerSize.ref}
      {...stackProps}
    >
      {items.length > 0 ? (
        <>
          {alignItems === 'end' && spacer}
          {items
            .slice(0, numVisible)
            .map((item, index) => itemRenderer(item, numVisible, index))}
          {maybeOverflow}
          {alignItems === 'start' && spacer}
        </>
      ) : null}
    </Stack>
  );
}
