import { Menu } from '@mui/material';
import IconButton from './IconButton';
import MoreIcon from '@watershed/icons/components/More';
import { ComponentProps, useRef, useState } from 'react';
import { useLingui } from '@lingui/react/macro';

function MoreActionsButtonControlled({
  isOpen,
  setIsOpen,
  children,
  icon,
  ...props
}: {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
  icon: JSX.Element;
} & Omit<ComponentProps<typeof IconButton>, 'children'>) {
  const { t } = useLingui();
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      <IconButton
        ref={buttonRef}
        onClick={() => setIsOpen(true)}
        color="primary"
        title={t({
          context:
            'button copy for a generic data grid component that opens a menu of possible actions to take on a row',
          message: 'Actions',
        })}
        aria-controls={props.id ?? 'more-actions'}
        aria-haspopup="true"
        size="large"
        {...props}
      >
        {icon}
      </IconButton>
      <Menu
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onClick={() => setIsOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={props.id ?? 'more-actions'}
      >
        {children}
      </Menu>
    </>
  );
}

export default function MoreActionsButton({
  children,
  icon,
  ...props
}: { children: React.ReactNode; icon?: JSX.Element } & Omit<
  ComponentProps<typeof IconButton>,
  'children'
>) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MoreActionsButtonControlled
      {...props}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      icon={icon ?? <MoreIcon />}
    >
      {children}
    </MoreActionsButtonControlled>
  );
}
