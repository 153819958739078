import { mixinSx } from '@watershed/style/styleUtils';
import disableInteractivity from '../../utils/DataGrid/disableInteractivity';
import {
  DataGrid,
  GridSlotsComponentsProps,
  GridValidRowModel,
  DataGridProps,
  gridClasses,
  useGridRootProps,
} from '../DataGrid/DataGrid';
import { useMemo, ReactNode, useCallback } from 'react';
import { OVERFLOW_MENU_CLASS_NAME } from './ObjectListOverflowMenu';
import { ObjectListSelectionFooter } from './ObjectListSelectionFooter';
import { ButtonProps } from '../Button';
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  type SxProps,
  type Theme,
} from '@mui/material';
import BoxLink, { type BoxLinkProps } from '../BoxLink';
import clsx from 'clsx';
import TruncatingTypographyWithTooltip from '../TruncatingTypographyWithTooltip';
import { DropdownMenuProps } from '../DropdownMenu';

export type FooterAction =
  | {
      type: 'button';
      props: Pick<
        ButtonProps,
        'children' | 'onClick' | 'disabled' | 'startIcon'
      >;
      disabledTooltip?: string;
    }
  | {
      type: 'divider';
    }
  | {
      type: 'menu';
      props: Pick<DropdownMenuProps, 'items' | 'trigger'>;
    };

// Augment the props for the footer slot. Unfortunately this is global for all
// DataGrid, so prefix with something specific to this use case.
declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    objectListSelectionFooterActions?: Array<FooterAction>;
  }
}

const BASE_CLASSNAME = 'UIObjectList';
const STANDARD_SIZE_CLASSNAME = 'UIObjectList-standard';
const DENSE_SIZE_CLASSNAME = 'UIObjectList-dense';
export const OBJECT_LIST_STANDARD_ROW_HEIGHT = 80;
export const OBJECT_LIST_DENSE_ROW_HEIGHT = 48;
const TITLE_CELL_CLASSNAME = 'UIObjectListTitleCell';
const TITLE_CELL_SELECTOR = `a.${TITLE_CELL_CLASSNAME}`;

export type ObjectListProps<R extends GridValidRowModel> = DataGridProps<R> & {
  dense?: boolean;
  noCard?: boolean;
};

const titleSx: SxProps<Theme> = {
  [`.${DENSE_SIZE_CLASSNAME} &`]: {
    fontWeight: (theme) => theme.typography.fontWeightRegular,
  },
  display: 'inline-block',
  transition: (theme) =>
    theme.transitions.create('color', {
      duration: theme.transitions.duration.shortest,
    }),
  // Turn blue on hover IF
  // - title cell is a link you’re hovering
  // - row is hovered and there’s no checkbox (b/c clicking will open link)
  [`${TITLE_CELL_SELECTOR}:hover &, .${gridClasses['row']}:hover:not(:has(.${gridClasses['cellCheckbox']})) ${TITLE_CELL_SELECTOR} &`]:
    { color: 'primary.main' },
};

export function ObjectList<R extends GridValidRowModel>(
  props: ObjectListProps<R>
) {
  // DataGrid v6.x does not have a way to globally disable sorting. When we
  // upgrade to v7.x, we can replace this with `disableColumnSorting`.
  const columns = useMemo(
    () =>
      props.columns
        .map((column) => ({
          ...column,
          sortable: false,
        }))
        .concat(
          // Annoying hack: because of the 4px padding + 1px border we add
          // around the table contents, and because the MUI column width
          // algorithm is fixed assuming no extra padding + border, it ends up
          // adding a horizontal scrollbar to the table. We try to get around
          // this by adding a negative margin + padding on the virtual scroller
          // element, but then the last column extends ~12px past the right edge
          // of the table. To fix this, we add a dummy column with a fixed
          // width.
          {
            field: '',
            headerName: '',
            minWidth: 12,
            maxWidth: 12,
            sortable: false,
          }
        ),
    [props.columns]
  );

  const disableRowSelectionOnClick =
    props.disableRowSelectionOnClick ?? !props.checkboxSelection;

  // We should do this in a less hacky way, but this is a quick fix for DES-289
  // https://linear.app/watershed/issue/DES-289/strange-flicker-in-gridtoolbarquickfilter-with-server-side-filter
  const ToolbarSlot = props.slots?.toolbar;

  const toolbar = useCallback(
    (toolbarProps: GridSlotsComponentsProps['toolbar']) => (
      <>
        {ToolbarSlot ? (
          <Box marginBottom={2}>
            <ToolbarSlot {...toolbarProps} />
          </Box>
        ) : null}
      </>
    ),
    [ToolbarSlot]
  );

  // Also a quick fix for DES-289
  const FooterSlot = props.slots?.footer;

  const footer = useCallback(
    (footerProps: GridSlotsComponentsProps['footer']) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const rootProps = useGridRootProps();

      // Pagination lives in the footer, so we need to render it here,
      // like the default `footer` slot implementation does.
      // https://github.com/mui/mui-x/blob/eb5b6fd92f6efd9978f29b4a5a775f17b1071aaf/packages/x-data-grid/src/components/GridFooter.tsx#L36C1-L40C9
      const paginationElement = rootProps.pagination &&
        !rootProps.hideFooterPagination &&
        rootProps.slots.pagination && (
          <rootProps.slots.pagination {...rootProps.slotProps?.pagination} />
        );
      return (
        <>
          {FooterSlot ? <FooterSlot {...footerProps} /> : paginationElement}
          <ObjectListSelectionFooter {...footerProps} />
        </>
      );
    },
    [FooterSlot]
  );

  return (
    <DataGrid
      // BigRocked styling will mess stuff up. Need to be explicit because the
      // BigRockContext can set it to true.
      bigRocked={false}
      {...disableInteractivity}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      {...props}
      columns={columns}
      columnHeaderHeight={44}
      rowHeight={
        props.dense
          ? OBJECT_LIST_DENSE_ROW_HEIGHT
          : OBJECT_LIST_STANDARD_ROW_HEIGHT
      }
      className={clsx(
        BASE_CLASSNAME,
        props.dense ? DENSE_SIZE_CLASSNAME : STANDARD_SIZE_CLASSNAME
      )}
      onRowClick={(params, event, details) => {
        if (props.onRowClick) {
          props.onRowClick(params, event, details);
        }
        // ObjectList.TitleCell is often an <a> tag which opens the item.
        // Sometimes, we want to extend the clickable area to the entire row.
        // When checkbox selection is enabled, however, that takes precedence.
        try {
          const target = event.target as HTMLElement;
          // If checkbox selection is enabled & row click selection isn't disabled,
          // do that & stop our shenanigans.
          // If there’s no checkbox selection, check if the click was somewhere on
          // the row that was not the title cell or a child of the title cell.
          if (
            props.checkboxSelection ||
            (!disableRowSelectionOnClick &&
              (!target ||
                target.tagName === 'A' ||
                target.closest(TITLE_CELL_SELECTOR) != null))
          ) {
            return false;
          }
          // When users click part of the row that isn't the title, we want to
          // open the item as well by clicking that link for them.
          // When the title cell is clicked, its stopPropagation() will prevent
          // this from running a second time.
          const titleCell = event.currentTarget
            ?.closest('.MuiDataGrid-row')
            ?.querySelector(TITLE_CELL_SELECTOR) as
            | HTMLAnchorElement
            | null
            | undefined;
          if (titleCell && titleCell?.tagName === 'A') {
            titleCell.click();
          }
        } catch (e) {}
      }}
      slots={{
        ...props.slots,
        toolbar,
        footer,
      }}
      sx={mixinSx(
        {
          borderRadius: '10px',
          borderWidth: 0,
          fontSize: (theme) => theme.typography.body2.fontSize,
          backgroundColor: 'transparent',
          color: (theme) => theme.palette.text.secondary,
          [`& .${gridClasses.main}`]: {
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
            padding: '4px 4px', // extra pixel horizontally to make up for -1px margin
            backgroundColor: (theme) => theme.palette.grey10,
            fontSize: (theme) => theme.typography.body2.fontSize,
          },
          // Remove border styles from header / rows as they are handled by the cells.
          [`& .${gridClasses.main} .MuiDataGrid-withBorderColor`]: {
            borderColor: 'transparent',
          },
          // Disable focus outline on cells.
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
            {
              outline: 'none',
            },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: 'none',
            },
          [`&& .${gridClasses.cell}:has(.${OVERFLOW_MENU_CLASS_NAME})`]: {
            // For the overflow menu cell, we need to see the menu overflow outside of the cell.
            overflow: 'visible',
          },
          [`& .${gridClasses.virtualScroller}`]: {
            border: (theme) =>
              `1px solid ${
                props.noCard ? 'transparent' : theme.palette.divider
              }`,
            borderRadius: '6px',
            overflowX: 'hidden',
          },
          [`& .${gridClasses.virtualScrollerContent}`]: {
            background: (theme) =>
              props.noCard ? 'transparent' : theme.palette.white,
            borderRadius: '6px',
            // Overflow hidden + position relative to clip the extra column space from view.
            overflow: 'hidden',
            position: 'relative',
          },
          [`& .${gridClasses.row}:last-of-type`]: {
            borderBottomWidth: 0,
          },
          [`& .${gridClasses.columnSeparator}`]: {
            display: 'none',
          },
          // Bizarrely, with the extra hacky column we add at the end, the
          // `columnHeader--moving` class is applied, which changes the
          // background color. Undo that.
          [`& .${gridClasses['columnHeader--moving']}`]: {
            backgroundColor: 'inherit',
          },
          [`& .${gridClasses['cell']}`]: {
            '& .MuiFormControl-fullWidth, & .MuiInputBase-root': {
              width: 'fit-content',
              '& svg': {
                width: 16,
                height: 16,
                flexShrink: 0,
                fill: (theme) => theme.palette.text.secondary,
              },
            },
            '& .MuiFormControl-root .MuiOutlinedInput-root, & .MuiInputBase-root .MuiInput-input, & .UIPopoverButton':
              {
                backgroundColor: 'transparent',
                width: 'fit-content',
                '&:not(:focus-visible)': {
                  boxShadow: 'none',
                },
                '.MuiDataGrid-cell--textLeft &': {
                  paddingLeft: '0 !important',
                },
              },
            '& .UIPopoverButton': {
              fontWeight: 'inherit',
            },
            '& .MuiDataGrid-cell--textLeft .MuiSelect-select': {
              padding: '4px 36px 4px 0 !important',
            },
          },
        },
        // Row should have cursor: pointer if checkbox selection or
        // has a linked title cell, which makes clicking anywhere open
        props.checkboxSelection
          ? {
              [`& .${gridClasses['row']}`]: {
                cursor: 'pointer',
              },
            }
          : {
              [`& .${gridClasses['row']}:has(${TITLE_CELL_SELECTOR})`]: {
                cursor: 'pointer',
              },
            },
        props.sx
      )}
    />
  );
}

ObjectList.TitleCell = function ObjectListTitleCell({
  href,
  title,
  startAdornment,
  endAdornment,
  subtitle,
  sx,
  isDisabled,
  disabledTooltip,
  ...props
}: {
  href?: string;
  title: ReactNode;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  subtitle?: ReactNode;
  isDisabled?: boolean;
  disabledTooltip?: ReactNode;
} & Omit<BoxLinkProps, 'href' | 'children' | 'title'>) {
  const Component = href ? BoxLink : Box;
  return (
    <Tooltip
      title={isDisabled && disabledTooltip ? disabledTooltip : ''}
      placement="right"
    >
      <Component
        {...props}
        className={TITLE_CELL_CLASSNAME}
        href={href}
        sx={mixinSx(
          {
            paddingBlockEnd: 1.5,
            marginInline: '-10px', // replace cell padding to increase clickable area
            width: 'calc(100% + 20px)', // cover maximum area
            height: '100%',
            textDecoration: 'none',
            outlineOffset: '-6px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [`.${STANDARD_SIZE_CLASSNAME} &`]: {
              // vertically align content to text height, horizontally replace cell padding
              paddingBlockStart: 2,
              paddingInlineStart: 2,
              paddingInlineEnd: '10px', // same as cell padding
            },
            [`.${DENSE_SIZE_CLASSNAME} &`]: {
              paddingInlineStart: '10px', // same as cell padding
              justifyContent: 'center',
              paddingBlockStart: 1.5,
            },
          },
          sx
        )}
        onClick={
          href
            ? // When title is a link and not disabled, stop propagation to prevent row selection
              (e) => {
                e.stopPropagation();
              }
            : undefined
        }
      >
        <Stack direction="row" gap={1} alignItems="center" width="100%">
          {startAdornment}
          {title != null ? (
            <TruncatingTypographyWithTooltip
              variant={isDisabled ? 'h5' : 'h4'}
              tooltip={title}
              tooltipProps={{
                placement: 'right',
              }}
              sx={titleSx}
            >
              {title}
            </TruncatingTypographyWithTooltip>
          ) : (
            <Typography variant={isDisabled ? 'h5' : 'h4'} sx={titleSx}>
              {title}
            </Typography>
          )}
          {endAdornment}
        </Stack>
        {subtitle && (
          <Typography variant="body2" noWrap>
            {subtitle}
          </Typography>
        )}
      </Component>
    </Tooltip>
  );
};
