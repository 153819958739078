import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Stack,
  Typography,
} from '@mui/material';
import { Tab, Tabs } from '@watershed/ui-core/components/Tab';
import { useQueryParam } from '@watershed/shared-frontend/utils/queryParamHooks';
import Button from '@watershed/ui-core/components/Button';
import OrgQuickLinks from '../OrgQuickLinks';
import { urlForObject } from '@watershed/shared-universal/adminRoutes';
import { LoginAsButtons } from '../LoginAsButtons';
import copyToClipboard from 'copy-to-clipboard';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import {
  FavoriteOrgButton,
  useFavoriteOrgs,
  useReferenceOrgs,
} from '../../utils/referenceOrgUtils';
import ShareIcon from '@watershed/icons/components/Share';
import UploadIcon from '@watershed/icons/components/Upload';
import Callout from '@watershed/shared-frontend/components/Callout';
import WarningIcon from '@watershed/icons/components/Warning';
import TextLink from '@watershed/ui-core/components/TextLink';

function FavoriteShareActions() {
  const { favoriteOrgIds, addFavoriteOrgs } = useFavoriteOrgs();
  const { enqueueSnackbar } = useSnackbar();
  const serializedOrgIds = favoriteOrgIds.join(',');
  const tryParseOrgIds = (value: string) => {
    // Just a baby sanity check on org_XXX
    const orgIds = value
      .split(',')
      .map((value) => value.trim())
      .filter((orgId) => orgId.startsWith('org_'));
    if (orgIds.length === 0) {
      return {
        success: false,
        message: 'No valid org ids found',
      };
    }
    return {
      success: true,
      orgIds,
    };
  };
  return (
    <Stack direction="row" gap={1}>
      <Button
        startIcon={<ShareIcon />}
        onClick={() => {
          copyToClipboard(serializedOrgIds);
          // Alert instead of snackbar to be more clear
          alert('Copied to clipboard! Send it to someone for import :)');
        }}
        disabled={favoriteOrgIds.length === 0}
        size="small"
      >
        Share favorites
      </Button>
      <Button
        startIcon={<UploadIcon />}
        onClick={() => {
          const value = prompt('Paste in the set of org ids (comma separated)');
          if (!value) return;
          const res = tryParseOrgIds(value ?? '');
          if (res.success && res.orgIds) {
            enqueueSnackbar('Imported org favorites!', { variant: 'success' });
            addFavoriteOrgs(res.orgIds);
          } else {
            enqueueSnackbar(res.message, { variant: 'error' });
          }
        }}
        size="small"
      >
        Import favorites
      </Button>
    </Stack>
  );
}

export default function ReferenceOrgsSection({
  referenceOrgsOpen,
}: { referenceOrgsOpen: boolean }) {
  const [referenceOrgGroup, setReferenceOrgGroup] =
    useQueryParam('referenceOrgGroup');
  const referenceOrgGroups = useReferenceOrgs();
  const currentGroup =
    referenceOrgGroups.find((group) => group.name === referenceOrgGroup) ??
    referenceOrgGroups.at(0);
  return (
    <Collapse
      in={referenceOrgsOpen}
      unmountOnExit
      timeout={{
        enter: 500,
        exit: 300,
      }}
      easing={{
        enter: 'ease-in-out',
        exit: 'ease-in-out',
      }}
    >
      <Card>
        <CardHeader
          title="Reference orgs"
          titleTypographyProps={{
            variant: 'h3',
          }}
          subheader={
            <Typography variant="body3" color="textSecondary">
              Personal favorites (starred orgs) and key accounts - request
              changes in{' '}
              <TextLink href="https://watershedclimate.slack.com/archives/C07C3A0JD33">
                #reference-org-requests
              </TextLink>
              !
            </Typography>
          }
          sx={{
            // Reduce padding to make more dense
            paddingTop: 1.5,
            paddingX: 1.5,
            paddingBottom: 0,
          }}
          action={<FavoriteShareActions />}
        />
        <Box borderBottom={(theme) => `1px solid ${theme.palette.border}`}>
          <Tabs
            value={currentGroup?.name}
            onChange={(_, value) => setReferenceOrgGroup(value)}
            sx={{
              paddingX: 2,
            }}
          >
            {referenceOrgGroups.map((orgGroup) => {
              return (
                <Tab
                  key={orgGroup.name}
                  label={orgGroup.name}
                  value={orgGroup.name}
                  sx={{
                    // Reduce padding to make more dense
                    paddingY: 1,
                  }}
                />
              );
            })}
          </Tabs>
        </Box>
        <Stack
          sx={{
            background: (theme) => theme.palette.grey10,
            paddingX: 2,
            paddingY: 1,
          }}
          gap={1}
        >
          {currentGroup?.warning ? (
            <Callout
              IconComponent={WarningIcon}
              variant="warning"
              title={currentGroup.warning.title}
              description={currentGroup.warning.description}
            />
          ) : null}
          {currentGroup?.orgs.length ? (
            <Stack
              direction="row"
              gap={1}
              flexWrap="wrap"
              key={currentGroup.name}
            >
              {currentGroup.orgs.map((org) => {
                return (
                  <Card
                    key={org.orgId}
                    sx={{
                      width: 280,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <CardActionArea
                      href={urlForObject('Organization', org.orgId)}
                      sx={{
                        flex: 1,
                        maxHeight: 180,
                        overflow: 'auto',
                      }}
                    >
                      <CardContent
                        sx={{
                          // Reduce padding to make more dense
                          padding: 1.5,
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={0.5}
                          justifyContent="space-between"
                        >
                          <Typography variant="h4">{org.orgName}</Typography>
                          <FavoriteOrgButton orgId={org.orgId} />
                        </Stack>
                        <Typography variant="body3">
                          {org.description}
                        </Typography>
                        <Stack
                          direction="row"
                          marginTop={1}
                          gap={0.5}
                          flexWrap="wrap"
                        >
                          {org.labels.map((label) => {
                            return (
                              <Chip
                                key={label.label}
                                size="small"
                                sx={{
                                  background: (theme) =>
                                    // @ts-expect-error this comes from shared-universal so it's just a string
                                    (theme.palette[label.color] as
                                      | string
                                      | undefined) ?? theme.palette.lightGrey,
                                  color: (theme) => theme.palette.white,
                                }}
                                label={label.label}
                              />
                            );
                          })}
                        </Stack>
                      </CardContent>
                    </CardActionArea>
                    <CardActions
                      sx={{
                        borderTop: (theme) =>
                          `1px solid ${theme.palette.border}`,
                        justifyContent: 'space-between',
                        padding: 0.5,
                      }}
                    >
                      <LoginAsButtons targetOrgId={org.orgId}>
                        Login
                      </LoginAsButtons>
                      <OrgQuickLinks
                        abbreviated
                        org={{
                          id: org.orgId,
                          name: org.orgName,
                        }}
                      />
                    </CardActions>
                  </Card>
                );
              })}
            </Stack>
          ) : (
            currentGroup?.emptyState
          )}
        </Stack>
      </Card>
    </Collapse>
  );
}
