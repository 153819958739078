import { FormikProps, FormikValues, useFormik } from 'formik';
import type {
  GridFeatureMode,
  GridFilterModel,
} from '@watershed/ui-core/components/DataGrid/DataGrid';
import { useCallback, useState } from 'react';

export type DataGridFilterProps<T extends FormikValues> = {
  filtersFormik: FormikProps<T>;
  filters: T;
};

/**
 * If you are applying server-side filters on a DataGrid,
 * this is the hook to use. This allows you to create a Formik
 * context upstream of the your query, passing the resulting
 * values into the query.
 *
 * Then you can use a custom Toolbar component to render the form
 * fields. (See DataGridToolbar for more details on that part!)
 *
 * Here is an example of the whole things working together:
 *
 * type GridFilters = {
 *   myFilterKey: string;
 * };
 *
 * function Toolbar() {
 *   return (
 *     <DataGridToolbar
 *        title="My DataGrid"
 *        actions={
 *          <Stack direction="row" gap={1}>
 *            <FilterIcon color={(theme) => theme.palette.secondary.dark} />
 *            <MyFilterField id="myFilterKey" />
 *          </Stack>
 *        }
 *      />
 *    );
 * }
 *
 * export default function MyPage() {
 *   const { filters, dataGridFilterProps } = useDataGridFilters<GridFilters>({
 *     myFilter: '',
 *   });
 *   const [result] = useMyPageQuery({
 *      variables: {
 *        myFilter,
 *      },
 *    });
 *
 *    const data = getGqlResultData(result);
 *    const rows = flattenConnection(data?.myRows);
 *
 *    return (
 *      <PageContainer>
 *        <DataGrid<GQMyRowFragment, GridFilters>
 *          rows={rows}
 *          columns={columns}
 *          slots={{ toolbar: Toolbar }}
 *          loading={isFetchingOrStale(result)}
 *          {...dataGridFilterProps}
 *        />
 *      </PageContainer>
 *    );
 * }
 *
 */
export default function useDataGridFilters<T extends FormikValues>(
  initialValues: T
): {
  filters: T;
  dataGridFilterProps: DataGridFilterProps<T>;
} {
  const filtersFormik = useFormik<T>({
    initialValues,
    onSubmit: () => {},
    enableReinitialize: true,
  });
  const filters = filtersFormik.values;
  return {
    filters,
    dataGridFilterProps: {
      filtersFormik,
      filters,
    },
  };
}

/**
 * The original version of this hook was built on Formik,
 * which we don't really use any more. As soon as a clear winner
 * emerges for form state management, we could remove the Formik
 * version. For now, this version relies on MUI DataGrid semantics.
 */
export function useDataGridFiltersV2(filterMode: GridFeatureMode = 'server') {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const onFilterModelChange = useCallback(
    (model: GridFilterModel) => setFilterModel(model),
    [setFilterModel]
  );
  return {
    filterModel,
    dataGridFilterProps: { filterModel, onFilterModelChange, filterMode },
  };
}

export type DataGridFilterV2Props = {
  filterModel: GridFilterModel;
  onFilterModelChange: (filterModel: GridFilterModel) => void;
  filterMode: GridFeatureMode;
};
