import { Teams } from '@watershed/constants/teams';
import { setOwnerContext } from '../utils/ownerContext';
import hoistNonReactStatics from 'hoist-non-react-statics';

export function useOwner(team: Teams) {
  setOwnerContext(team);
}

export function withOwner<P extends {}>(
  team: Teams,
  Component: React.ComponentType<P>
) {
  function WithOwner(props: P) {
    useOwner(team);
    return <Component {...props} />;
  }

  WithOwner.displayName = `WithOwner(${
    Component.displayName ?? Component.name
  })`;

  // Hoist any non-React statics from the wrapped component to the HoC, such as
  // `layout`.
  return hoistNonReactStatics(WithOwner, Component);
}
