import AdminPageContainer from '../components/AdminPageContainer';
import { useOwner } from '@watershed/shared-frontend/hooks/useOwner';
import { Teams } from '@watershed/constants/teams';
import useToggle from '@watershed/ui-core/hooks/useToggle';
import OrgList from '../components/entFound/OrgList';
import ReferenceOrgsSection from '../components/entFound/ReferenceOrgsSection';

export default function OrganizationListPage() {
  useOwner(Teams.EnterpriseFoundations);
  const [referenceOrgsOpen, toggleReferenceOrgs] = useToggle(true);
  return (
    <AdminPageContainer
      breadcrumbs={[{ title: 'Organizations' }]}
      bodySx={{
        display: 'grid',
        gridTemplateRows: referenceOrgsOpen ? 'auto 1fr' : '1fr',
        height: 'calc(100vh - 50px)',
      }}
    >
      <ReferenceOrgsSection referenceOrgsOpen={referenceOrgsOpen} />
      <OrgList
        referenceOrgsOpen={referenceOrgsOpen}
        toggleReferenceOrgs={toggleReferenceOrgs}
      />
    </AdminPageContainer>
  );
}
