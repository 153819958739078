import { useLayoutEffect, useRef, useState } from 'react';

/**
 * Returns a function that will schedule a layout effect to run on the next
 * render.
 */
export default function useScheduleLayoutEffect() {
  const [s, ss] = useState<object>();
  const fns = useRef(new Map<string | number, () => void>());

  useLayoutEffect(() => {
    const fnCopy = Array.from(fns.current.values());
    fns.current = new Map();
    fnCopy.forEach((f) => f());
  }, [s]);

  return (id: string | number, cb: () => void) => {
    fns.current.set(id, cb);
    ss({});
  };
}
