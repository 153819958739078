import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Form as FormikForm } from 'formik';
import { mixinSx } from '@watershed/style/styleUtils';

export const formSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  // We need to investigate why this is here as it leads to the content being 20px from footer by default.
  gap: 2.5,
  '& fieldset': {
    border: 0,
    margin: 0,
    padding: 0,
  },
  '& fieldset legend': {
    padding: 0,
  },
  '& label + .MuiFormGroup-root, & label + .MuiInput-formControl': {
    marginTop: 0.5,
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formColumns: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: theme.spacing(2.5),
    },
  })
);

const Form = forwardRef<
  HTMLFormElement,
  ComponentPropsWithoutRef<typeof FormikForm> & { sx?: SxProps<Theme> }
>(function Form(props, ref) {
  const { sx, ...rest } = props;
  return (
    <Box component={FormikForm} {...rest} sx={mixinSx(formSx, sx)} ref={ref} />
  );
});

export default Form;

export function FormColumns(props: ComponentPropsWithoutRef<'div'>) {
  const classes = useStyles();
  return (
    <div {...props} className={clsx(props.className, classes.formColumns)} />
  );
}
