/**
 * This file contains a critical set of hard-coded Product org IDs and content
 * used to make reference orgs available and easy to find in Admin.
 */

import uniq from 'lodash/uniq';

export interface ReferenceOrgLabel {
  label: string;
  color: string; // keyof Palette; no longer possible from shared-universal :shrug:
  description: string;
}
export interface ReferenceOrg {
  orgId: string;
  orgName: string;
  description: string;
  labels: Array<ReferenceOrgLabel>;
}
export interface ReferenceOrgGroup {
  name: string;
  orgs: Array<ReferenceOrg>;
  emptyState?: React.ReactNode;
  warning?: {
    title: React.ReactNode;
    description: React.ReactNode;
  };
}

/**
 * These org labels are kind of defaults, and we don't need them as separate tabs.
 */
export const OMITTABLE_ORG_LABELS = [
  'demo',
  'test',
  'supplier',
  'customer',
] satisfies Array<keyof typeof ORG_LABELS>;

export const ORG_LABELS = {
  demo: {
    color: 'grass',
    label: 'Demo',
    description: 'Demo org',
  },
  test: {
    color: 'afternoon',
    label: 'Test',
    description: 'Test org',
  },
  customer: {
    color: 'poppy',
    label: 'Customer',
    description: 'Real customer',
  },
  supplier: {
    color: 'lightGrey',
    label: 'Supplier',
    description: 'Supplier',
  },
  canonicalDemo: {
    color: 'cobalt70',
    label: 'Canonical Demo',
    description:
      'Canonical demo: See - https://docs.google.com/spreadsheets/d/1FmICIK_fV-Dk1rdf-97Yi6lZfczDxXUFVmiO8KOExc8/edit?gid=0#gid=0',
  },
  csrd: {
    color: 'forest',
    label: 'CSRD',
    description: 'CSRD',
  },
  benchmarks: {
    color: 'grey50',
    label: 'Benchmarks',
    description: 'Demo for benchmarks',
  },
  customDatasets: {
    color: 'grey50',
    label: 'Custom Datasets',
    description: 'Demo for custom datasets',
  },
  dataAnomalies: {
    color: 'grey50',
    label: 'Data anomalies',
    description: 'Demo for custom datasets',
  },
  savedViews: {
    color: 'grey50',
    label: 'Saved views',
    description: 'Demo for saved views',
  },
  supplyChain: {
    color: 'grey50',
    label: 'Supply Chain',
    description: 'Demo for supply chain',
  },
  productModule: {
    color: 'grey50',
    label: 'Product Module',
    description: 'Demo for Product module',
  },
  reductionPlans: {
    color: 'grey50',
    label: 'Reduction Plans',
    description: 'Demo for Reduction Plans',
  },
  reporting: {
    color: 'grey50',
    label: 'Reporting',
    description: 'Demo for Reduction Plans',
  },
} satisfies Record<string, ReferenceOrgLabel>;

/**
 * These orgs are considered "reference" orgs in that they have been tweaked
 * manually by the go-to-market teams, and therefore they should be blocked
 * from being reset by Demo Platform.
 *
 * Of course, this is anathema to Demo Platform. We want GTM folks to be able
 * to spin up a highly customized, high fidelity demo in minutes! But we aren't
 * quite there yet, so the reference org world lives on, and we need to protect
 * it.
 *
 * As an escape hatch, if you really know what you are doing you can enable the
 * `DemoPlatformEnableForRestrictedOrg` feature flag temporarily for the org,
 * and then you will be able to reset it using Demo Platform. Please disable
 * the flag immediately after to return it to its protected state.
 */
export const REFERENCE_ORGS: Array<ReferenceOrg> = [
  {
    orgId: 'org_2W84ojNUvAqx64mJ4UHn',
    orgName: 'EverythingCo',
    description: 'Luxury / Beauty focused CPG',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.savedViews,
      ORG_LABELS.supplyChain,
      ORG_LABELS.productModule,
      ORG_LABELS.reductionPlans,
    ],
  },
  {
    orgId: 'org_2Mz4mWnaeRDsijyeYnNe',
    orgName: 'FoodCo Demo',
    description:
      'Food / Hosptiality. FoodCo is more the companies that make the food.',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.savedViews,
      ORG_LABELS.supplyChain,
      ORG_LABELS.reductionPlans,
    ],
  },
  {
    orgId: 'org_2WV4r7GvPt6LWWsJTvXG',
    orgName: 'Healthcare Co',
    description: 'Healthcare: hospital systems, pharma, medical devices',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.customDatasets,
      ORG_LABELS.dataAnomalies,
      ORG_LABELS.supplyChain,
      ORG_LABELS.productModule,
    ],
  },
  {
    orgId: 'org_2aA4rvNXyrnnCy65L3Jj',
    orgName: 'LogisticsCo',
    description:
      'Delivery Services (e.g. doordash), Freight Companies, Relocation Services, Logistics Companies',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.customDatasets,
      ORG_LABELS.dataAnomalies,
      ORG_LABELS.savedViews,
      ORG_LABELS.reductionPlans,
      ORG_LABELS.reporting,
    ],
  },
  {
    orgId: 'org_2664tJogzhsTTUPd1sCw',
    orgName: 'Real Estate Co',
    description: 'REITs, Brokers, Companies with large real estate holdings',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.customDatasets,
      ORG_LABELS.dataAnomalies,
      ORG_LABELS.reporting,
    ],
  },
  {
    orgId: 'org_2W54oNRUuiP9Gif2rNzU',
    orgName: 'Manufacturing Co',
    description:
      'Any company that has large factories or manufactures products. Examples include: tires, metals, gizmos, etc.',
    labels: [
      ORG_LABELS.canonicalDemo,
      ORG_LABELS.benchmarks,
      ORG_LABELS.savedViews,
      ORG_LABELS.supplyChain,
      ORG_LABELS.productModule,
    ],
  },
  {
    orgId: 'org_2sL5LVgVHDNZRDGb9QaY',
    orgName: 'CSRD Demo',
    description: 'Demo org for CSRD',
    labels: [ORG_LABELS.csrd, ORG_LABELS.reporting],
  },
];

// Not sure why these aren't in the list above, but they were in a
// spreadsheet somewhere so :shrug:
const otherCanonicalDemoOrgs = [
  'org_2B53jxxQhQfLA4WXmaR8', // Pine Hill
  'org_2VD4Kx7smA9tjyDMhFaJ', // Retail Co
  'org_2uk4Go1dmHer1c4JyEh1', // Footwear Co
  'org_2HW45NP5w9iCKKkkvYoo', // Apparel Co
  'org_1qv1TFModewVANRP2wLk', // DemoCo
];

/**
 * These orgs are used for Playwright smoke tests. They cannot be reset
 * manually by Demo Platform.
 *
 * The smoke tests should not mutate data, but rather just click through a
 * team's key flows. The idea is that because they do not mutate data, they
 * do not have to be reset between runs. Playwright therefore runs them every
 * ten minutes.
 *
 * You can generally avoid flakiness in E2E tests by relying on data-test
 * attributes and avoiding selectors that are likely to change. Certainly
 * avoid checking product copy.
 *
 * We would generally advise each team to create their own org for smoke tests,
 * but in practice teams tend to want to just use a shared one, so we don't
 * enforce anything on that front.
 *
 * playwright_smoke.yml defines the various smoke tests, which point to test
 * folders. Inside these test folders are env variables with email/pass
 * combations:
 *    email: process.env.E2E_USERNAME_RELIABILITY_PROBER,
 *    password: process.env.E2E_PASSWORD_RELIABILITY_PROBER,
 *
 * It is essentially these email/pass combinations that point to the orgs,
 * which is pretty easy to find in the codebase. So let's do our best to keep
 * this list up to date, against all odds.
 *
 * Note: As of October 2024, a bunch of smoke tests point to the same org they
 * use for Full E2E tests. These appear flaky because sometimes the smoke tests
 * are running while the org is being reset. Don't do that! You're not gonna
 * have a good time.
 *
 * Teams should do whatever makes sense for them to keep the orgs in a state
 * where the playwright smoke tests run successfully.
 *
 * As an escape hatch, if you really know what you are doing you can enable the
 * `DemoPlatformEnableForRestrictedOrg` feature flag temporarily for the org,
 * and then you will be able to reset it using Demo Platform. Please disable
 * the flag immediately after to return it to its protected state.
 */
const SMOKE_TEST_ORGS = [
  'org_2YQ5iDCthh3if1w16TAD', // Reporting E2E Smoke
  'org_2TS4TKMvQh8NshmkFLi2', // Corporate E2E Smoke
  'org_2DS4TKHRu3PZRzCV6x4v', // Finance E2E Smoke
  'org_2yF4R68w7D81km2iNwVA', // Apparel E2E Smoke
  'org_2cD4p5CtzBgXrJRafmaj', // Reductions E2E Smoke
  'org_2Gv4fp1YhDYhaQ4HfJqf', // Footprint Platform E2E Smoke
  'org_2fx5HB9psqYJ1iiNqroU', // Reliability Prober
  'org_2zb5bYqKu1XmsMdR2ZMc', // Login E2E Smoke (EntFound)
  'org_2m45oMWQGH38udQjvysa', // Enterprise Foundations E2E Smoke
  'org_2W84ojNUvAqx64mJ4UHn', // EverythingCo
  'org_2sL5LVgVHDNZRDGb9QaY', // CSRD Demo

  'org_2tY4dMVxKxoT8b3TV1Y4', // Measurement Test Suite, Non-Playwright
  'org_2pE5kU76CKdqJoMpA7cG', // CSRD non-playwright cron job smoke test (backgroundReportAnswerVerifierWorkflow)
];

const orgIdsThatCannotBeReset = uniq([
  ...REFERENCE_ORGS.map((org) => org.orgId),
  ...otherCanonicalDemoOrgs,
  ...SMOKE_TEST_ORGS,
]);

export default orgIdsThatCannotBeReset;
