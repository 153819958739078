import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { maybeNotifySentry } from '../utils/errorUtils';

export function parseStoredValue<T>(
  storedJson: string | null,
  storageKey: string,
  defaultValue: T,
  parse?: (o: unknown) => T,
  deserialize: (o: string) => unknown = JSON.parse
) {
  let storedValue: unknown;
  if (storedJson) {
    try {
      storedValue = deserialize(storedJson);
    } catch (err) {
      console.warn(`Invalid JSON in storage for key: ${storageKey}`);
    }
  }
  if (parse) {
    try {
      return parse(storedValue);
    } catch (err) {
      // don't warn if storedValue is undefined (i.e. the key doesn't exist in local storage yet)
      if (storedValue !== undefined) {
        console.warn(
          `Failed to parse / validate the JSON in storage for key: ${storageKey}. Returning default. ${err}`
        );
        maybeNotifySentry(err, 'warning');
      }
      return defaultValue;
    }
  } else if (typeof storedValue === typeof defaultValue) {
    return storedValue as T;
  } else {
    return defaultValue;
  }
}

export function getFromStorage<T>(
  storageKey: string,
  defaultValue: T,
  storage: Storage,
  parse?: (o: unknown) => T
) {
  return parseStoredValue(
    storage.getItem(storageKey),
    storageKey,
    defaultValue,
    parse
  );
}

export function setStorage<T>(
  storageKey: string,
  newValue: T,
  storage: Storage
) {
  try {
    storage.setItem(storageKey, JSON.stringify(newValue));
  } catch (err) {
    console.warn(`Failed to store in storage for key: ${storageKey}. ${err}`);
  }
}

/**
 * @param parse allows us to validate that what's in storage matches a schema,
 * it's only used on get because the types should prevent an invalid set
 */

export default function useStorageState<T>(
  storageKey: string,
  defaultValue: T,
  storage: Storage,
  parse?: (o: unknown) => T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() =>
    getFromStorage(storageKey, defaultValue, storage, parse)
  );

  const setter = useCallback(
    (arg: SetStateAction<T>) => {
      setValue(arg);

      // Grab the new value based on the SetStateAction<T>. We use `as` here
      // since the compiler doesn't know for sure that `T` itself doesn't extend
      // `function` - in which case the runtime type of `arg` could be
      // 'function'. It's not appropriate to use this hook with a function type,
      // but it's hard to enforce that at compile time.
      //
      // One thing we tried was enforcing `T extends string | number | object |
      // ...`, but that caused type inference to work poorly (passing in a
      // `defaultValue` of '' would result in `T = ''` rather than `T = string`).
      const newValue =
        typeof arg === 'function' ? (arg as (v0: T) => T)(value) : arg;
      setStorage(storageKey, newValue, storage);
    },
    [storage, storageKey, value]
  );

  return [value, setter];
}
