import Icon, { IconProps } from '../Icon';
const StarFillIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M22.96 8.95c-.15-.375-.45-.6-.825-.675l-6.375-.9L12.91 1.6a.975.975 0 0 0-.9-.6c-.375 0-.75.225-.9.525l-2.85 5.85-6.375.9c-.375.075-.675.3-.825.675-.15.375 0 .75.225 1.05l4.65 4.5-1.125 6.375a.976.976 0 0 0 .375.975c.3.225.75.225 1.05.075l5.7-3 5.7 3c.3.15.75.15 1.05-.075.3-.225.45-.6.375-.975L17.935 14.5l4.65-4.5a.862.862 0 0 0 .375-1.05Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default StarFillIcon;
