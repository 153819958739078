import { Box, SxProps, Theme, Tooltip } from '@mui/material';
import { useLingui } from '@lingui/react/macro';

import Button, { ButtonProps } from './Button';
import CircleCheckmarkIcon from '@watershed/icons/components/CircleCheckmark';
import ProgressButton from './ProgressButton';
import {
  Dialog,
  DialogProps,
  DialogSectionHeader,
  DialogSectionHeaderProps,
} from './Dialog';
import Form, { formSx } from './Form/Form';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { mixinSx } from '@watershed/style/styleUtils';

const dialogFormSx: SxProps<Theme> = mixinSx(formSx, {
  paddingBlock: 2.5,
  '& > section': {
    marginTop: -2,
    paddingBottom: 2,
  },
});

interface DialogFormExtraProps {
  disabled?: boolean;
  disabledSubmitTooltip?: React.ReactNode;
  submit?: React.ReactNode;
  cancel?: React.ReactNode;
  submitIcon?: React.ReactNode;
  submitColor?: ButtonProps['color'];
  isSubmitting?: boolean;
  extraButton?: React.ReactNode;
  showCancelButton?: boolean;
}

export type DialogFormProps = DialogFormExtraProps &
  Pick<
    DialogProps,
    | 'variant'
    | 'header'
    | 'onClose'
    | 'maxWidth'
    | 'children'
    | 'className'
    | 'open'
    | 'disableBackdropClick'
    | 'sx'
    | 'contentSx'
    | 'componentSx'
  >;

function DialogForm({
  submit,
  disabled = false,
  submitIcon = <CircleCheckmarkIcon />,
  submitColor = 'primary',
  cancel,
  showCancelButton = true,
  disabledSubmitTooltip = undefined,
  className,
  header,
  isSubmitting,
  extraButton,
  componentSx,
  contentSx,
  ...props
}: DialogFormProps) {
  const { t } = useLingui();

  submit ??= t`Submit`;
  cancel ??= t`Cancel`;

  return (
    <Dialog
      {...props}
      disableEscapeKeyDown
      header={
        header
          ? {
              ...header,
              title: header?.title || '',
              hideClose:
                header['hideClose'] === undefined ? true : header['hideClose'],
            }
          : undefined
      }
      actions={
        <>
          {extraButton && (
            <>
              {extraButton}
              <Box flexGrow={1}></Box>
            </>
          )}
          {showCancelButton &&
            (typeof cancel === 'string' ? (
              <Button onClick={props.onClose} key="cancel">
                {cancel}
              </Button>
            ) : (
              cancel
            ))}
          <Tooltip
            title={disabled ? (disabledSubmitTooltip ?? '') : ''}
            sx={{
              // DialogActions applies spacing using a ":not(:first-of-type)" selector,
              // so we need to bring our own spacing for the disabled toolip
              marginLeft: 1,
            }}
          >
            {typeof submit === 'string' ? (
              <Box>
                <ProgressButton
                  type="submit"
                  color={submitColor}
                  startIcon={submitIcon}
                  disabled={disabled || isSubmitting}
                  isInProgress={!!isSubmitting}
                  key="submit"
                  data-test={TestIds.DialogFormSubmitButton}
                >
                  {submit}
                </ProgressButton>
              </Box>
            ) : (
              <>{submit}</>
            )}
          </Tooltip>
        </>
      }
      component={Form}
      componentSx={mixinSx(
        {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          gap: 0,
        },
        componentSx
      )}
      contentSx={mixinSx(dialogFormSx, contentSx)}
    />
  );
}

export default DialogForm;

export function DialogSplitForm({
  header,
  children,
  submit = 'Submit',
  submitIcon = <CircleCheckmarkIcon />,
  className,
  isSubmitting,
  disabled,
  sx,
  ...props
}: DialogFormExtraProps & {
  header?: DialogSectionHeaderProps;
  children: React.ReactNode;
  className?: string;
  sx?: SxProps<Theme>;
}) {
  return (
    <Form {...props} sx={mixinSx(dialogFormSx, sx)}>
      <>
        {header && <DialogSectionHeader {...header} />}
        {children}
        <Box
          display="flex"
          marginTop={1}
          paddingTop={2}
          justifyContent="flex-end"
          borderTop="1px solid"
          borderColor="divider"
        >
          {typeof submit === 'string' ? (
            <ProgressButton
              type="submit"
              color="primary"
              label={submit}
              startIcon={submitIcon}
              disabled={disabled || isSubmitting}
              isInProgress={!!isSubmitting}
            >
              {submit}
            </ProgressButton>
          ) : (
            submit
          )}
        </Box>
      </>
    </Form>
  );
}
